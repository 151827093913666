import axios from "axios";
let BASE_URL = "";

if (process.env.REACT_APP_NODE_ENV === "prod") {
  BASE_URL = process.env.REACT_APP_PROD_BASE_URL;
} else {
  BASE_URL = process.env.REACT_APP_DEV_BASE_URL;
}
// BASE_URL = process.env.REACT_APP_DEV_BASE_URL;

const prod = (headers) => {
  return axios.create({
    baseURL: BASE_URL,
    headers: {
      "auth-token": localStorage.getItem("auth-token"),
      ...headers,
    },
  });
};

export const APIUploadFiles = (data, callback) => {
  prod()
    .post("/file/upload", data)
    .then(function (response) {
      //console.log(response);
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      console.log(error);
      callback({ status: 400, data: error.response.data });
    });
};

export const APISignUp = (data, callback) => {
  prod()
    .post("/signup", data)
    .then(function (response) {
      //console.log(response);
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      console.log(error);
      callback({ status: 400, data: error.response.data });
    });
};

export const APILogin = (data, callback) => {
  prod()
    .post("/login", data)
    .then(function (response) {
      // console.log(response);
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      console.log(error);
      callback({ status: 400, data: error.response.data });
    });
};

export const auth = (callback) => {
  prod()
    .get("/auth")
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};

export const APIGetUserFiles = (data, callback) => {
  prod()
    .post("/user/list_files", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};

export const APIDeleteUserFile = (data, callback) => {
  prod()
    .post("/file/delete_file", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};

export const APIDeleteUserFiles = (data, callback) => {
  prod()
    .post("/file/delete_files", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};

export const APIAddUserFiles = (data, callback) => {
  prod()
    .post("/file/add_file", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};
export const APIGet10Files = (data, callback) => {
  prod()
    .post("/file/list_files", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};

export const GetUserProfile = (callback) => {
  prod()
    .get("/user/profile")
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};

// export const APIGetUserUsedSpace = (callback) => {
//   prod()
//     .get("/user/used_space")
//     .then(function (response) {
//       callback({ status: 200, data: response.data });
//     })
//     .catch(function (error) {
//       callback({ status: 400, data: error });
//     });
// };

export const APIUpdatePassword = (data, callback) => {
  prod()
    .post("/user/update_password", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};

export const APIUpdatePublicDetails = (data, callback) => {
  prod()
    .post("/user/update_public_details", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};
//
export const APIBankDetails = (data, callback) => {
  prod()
    .post("/user/update_bank_details", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIUploadImage = (data, callback) => {
  prod()
    .post("/user/upload_image_s3", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const UpdateWatermarkUID = (data, callback) => {
  prod()
    .post("/user/update_watermark_uid", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const UpdateProfile = (data, callback) => {
  prod()
    .post("/user/update_profile", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error.response.data });
    });
};

export const APIGetDailyAnalytics = (data, callback) => {
  prod()
    .post("/analytics/daily", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};
export const APIGetMonthlyAnalytics = (data, callback) => {
  prod()
    .post("/analytics/monthly", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};

export const APIGet50Files = (data, callback) => {
  prod()
    .post("/file/files", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};

export const APICreateNewRequests = (data, callback) => {
  prod()
    .post("/withdrawal/new", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};
export const APIGetTotalRevenue = (callback) => {
  prod()
    .get("/revenue/get")
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};

export const APIGetWithdrawalRequests = (query, callback) => {
  prod()
    .post("/withdrawal/get", query)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};

export const APIGetWebFileInfo = (data, callback) => {
  prod()
    .post("/file/temp_info", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};

export const APIGetPaymentProofs = (data, callback) => {
  prod()
    .get("/withdrawal/payment_proofs", { params: data })
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};

export const APISubmitContactUsForm = (data, callback) => {
  prod()
    .post("/public/contactus", data)
    .then(function (response) {
      callback({ status: 200, data: response.data });
    })
    .catch(function (error) {
      callback({ status: 400, data: error });
    });
};
