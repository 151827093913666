import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import loadable from "@loadable/component";
import Uppy from "@uppy/core";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import Loading from "./loading";
import { auth } from "./APIServices";
import useCheckMobileScreen from "./hooks/useCheckMobileScreen";
import DMCA from "./pages/dmca";

const SEO = {
  website: {
    title: "Diskwala | Download App",
    description: `Meet Diskwala – your go-to app for hassle-free file management and sharing, right from your fingertips! Whether you're on the go or chilling at home, Diskwala has got your back. With Diskwala, you can store all your files securely in the cloud and access them from anywhere, anytime. Need to share a file with a friend or colleague? No problem! Our intuitive interface makes it a breeze to share files effortlessly. But wait, there's more! Diskwala doesn't just store and share – it's also your personal file organizer. Easily organize your files into folders, rename them, or delete them with just a tap. And here's the best part: Diskwala's AI-powered security keeps your files safe from prying eyes. You can rest easy knowing that your sensitive information is protected with top-of-the-line encryption and smart security features. So, whether you're a student, a professional, or just someone who loves staying organized, Diskwala is the app for you. Try it out today and experience the convenience of cloud storage like never before!`,
  },
  contact: {
    title: "Diskwala | Contact Us",
    description: `Meet Diskwala – your go-to app for hassle-free file management and sharing, right from your fingertips! Whether you're on the go or chilling at home, Diskwala has got your back. With Diskwala, you can store all your files securely in the cloud and access them from anywhere, anytime. Need to share a file with a friend or colleague? No problem! Our intuitive interface makes it a breeze to share files effortlessly. But wait, there's more! Diskwala doesn't just store and share – it's also your personal file organizer. Easily organize your files into folders, rename them, or delete them with just a tap. And here's the best part: Diskwala's AI-powered security keeps your files safe from prying eyes. You can rest easy knowing that your sensitive information is protected with top-of-the-line encryption and smart security features. So, whether you're a student, a professional, or just someone who loves staying organized, Diskwala is the app for you. Try it out today and experience the convenience of cloud storage like never before!`,
  },
  payment: {
    title: "Payment Proofs | DiskWala",
    description:
      "Diskwala is the world's most trustworthy company for timely client payments, utilizing various global payment methods. With a minimum payout of just <b>$1</b> ($1 = 80 INR), Diskwala ensures payments are credited to your account within 7 days, providing reliable and efficient financial services you can count on.",
  },
  dashboard: {
    title: "Dashboard",
    description: `Welcome to Diskwala, your one-stop destination for storing and sharing files online! Our easy-to-use dashboard lets you handle your files like a pro – upload, edit, delete, whatever you need. And here's the cool part: we've got top-notch security powered by smart AI. Your files are super safe with us, thanks to fancy encryption and smart tech that keeps an eye out for any sneaky stuff. Plus, you can peek into your file stats with our analytics feature. It's like having a personal data detective, showing you how your files are doing and helping you make smart choices. Oh, and did we mention you can jazz up your Diskwala space with your own branding? Yep, make it look and feel just like you want it.`,
  },
  dmca: {
    title: "DMCA",
    description:
      "This document outlines the policies and procedures that DiskWala.com follows in accordance with the DMCA to handle claims of copyright infringement. Ensure that all the contact details are correctly filled in and that the procedure is strictly followed to comply with the law.",
  },
  pp: {
    title: "Privacy Policy",
    description:
      "Welcome to Diskwala's Privacy Policy page, where we prioritize transparency and protection of your personal information. Learn how we collect, use, and safeguard your data as you navigate our platform. By using Diskwala, you agree to our privacy practices outlined in this policy. Discover how we handle user-provided information, employ security measures, and ensure compliance with legal standards. Stay informed about your choices regarding account management and marketing communications. Plus, find out how we address children's privacy concerns and how you can contact us with any inquiries. Trust Diskwala to prioritize your privacy every step of the way. Thank you for choosing Diskwala.",
  },
  tnc: {
    title: "Terms and Condition",
    description:
      "Welcome to Diskwala's Terms and Conditions page, your guide to using our app responsibly and effectively. Learn about the rules and guidelines that govern your use of Diskwala's services, including uploading and viewing files, managing user accounts, and respecting intellectual property rights. Discover how we prioritize user privacy and handle third-party advertisements. Understand our policies regarding the deletion of uploaded content and our stance on intellectual property protection. Plus, find out about our limitations of liability and how we may update these terms over time. By using Diskwala, you agree to abide by these terms and conditions, ensuring a safe and secure experience for all users. Thank you for choosing Diskwala.",
  },
  downloadApp: {
    title: "Download App",
    description:
      "Welcome to Diskwala's App Download page – your gateway to convenient and secure file management! With Diskwala, accessing and sharing your files has never been easier. Simply download our app to your device and unlock a world of possibilities. Whether you're on the go or at home, Diskwala empowers you to view and download files uploaded to our platform with just a few clicks. Seamlessly access your documents, photos, videos, and more, all from the palm of your hand. Plus, with our intuitive interface, navigating through your files is a breeze. Say goodbye to cumbersome file management and hello to Diskwala – your trusted file-sharing companion. Get started today and revolutionize the way you manage your files!",
  },
  welcome: {
    title: "Welcome",
    description:
      "Welcome to Diskwala – your ultimate destination for seamless file management and sharing! Whether you're a new user looking to sign up or a returning user ready to dive back in, our login/signup welcome page is your starting point to unlocking the full potential of Diskwala. Sign up now to create your account and gain access to powerful features designed to simplify your digital life. Already a member? Log in to your account and pick up right where you left off. With Diskwala, you can securely store, share, and manage your files with ease. Join us today and experience the convenience of cloud storage done right!",
  },
};
const WelcomeMobile = loadable(() => import("./pages/welcome_mobile"), {
  fallback: <Loading />,
});
const Welcome = loadable(() => import("./pages/welcome"), {
  fallback: <Loading />,
});
const Videos = loadable(() => import("./pages/dashboard/videouploads"), {
  fallback: <Loading />,
});
const OpenApp = loadable(() => import("./pages/openapp"), {
  fallback: <Loading />,
});

const Dashboard = loadable(() => import("./pages/dashboard"), {
  fallback: <Loading />,
});

const Website = loadable(() => import("./pages/website"), {
  fallback: <Loading />,
});

const PP = loadable(() => import("./pages/pp"), {
  fallback: <Loading />,
});
const FileManager = loadable(() => import("./pages/dashboard/filemanager"), {
  fallback: <Loading />,
});
const Security = loadable(() => import("./pages/dashboard/security"), {
  fallback: <Loading />,
});
const Billing = loadable(() => import("./pages/dashboard/billing"), {
  fallback: <Loading />,
});
const Branding = loadable(() => import("./pages/dashboard/branding"), {
  fallback: <Loading />,
});
const Analytics = loadable(() => import("./pages/dashboard/analytics"), {
  fallback: <Loading />,
});
const TNC = loadable(() => import("./pages/tnc"), {
  fallback: <Loading />,
});
const BotInfo = loadable(() => import("./pages/dashboard/botinfo"), {
  fallback: <Loading />,
});

const Verification = loadable(() => import("./pages/dashboard/verification"), {
  fallback: <Loading />,
});

function AppRouter() {
  const [uppy, setUppy] = useState();
  const isMobile = useCheckMobileScreen();
  console.log(isMobile);
  // const [uppy] = useState(
  //   () =>
  //     new Uppy({
  //       debug: false,
  //       autoProceed: true,
  //       allowMultipleUploadBatches: "true",
  //       meta: { token: localStorage.getItem("auth-token") },

  //       restrictions: {
  //         allowedFileTypes: [
  //           "video/*",
  //           // "audio/*",
  //           // "image/*",
  //           // "text/*",
  //           // ".apk",
  //           // ".zip",
  //           // ".rar",
  //           // ".7z",
  //           // ".tar",
  //           // ".docx",
  //           // "application/msword",
  //           // "application/vnd.ms-excel",
  //           // "application/vnd.ms-powerpoint",
  //           // "text/plain",
  //           // "application/pdf",
  //         ],
  //       },
  //     })
  // );

  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [redirectUrl, setRedirectUrl] = useState("/");
  useEffect(() => {
    if (loggedIn) {
      setUppy(
        () =>
          new Uppy({
            debug: false,
            autoProceed: true,
            allowMultipleUploadBatches: "true",
            meta: { token: localStorage.getItem("auth-token") },

            restrictions: {
              allowedFileTypes: [
                "video/*",
                "video/x-matroska",
                "application/octet-stream",
                ".mkv",
                // "audio/*",
                // "image/*",
                // "text/*",
                // ".apk",
                // ".zip",
                // ".rar",
                // ".7z",
                // ".tar",
                // ".docx",
                // "application/msword",
                // "application/vnd.ms-excel",
                // "application/vnd.ms-powerpoint",
                // "text/plain",
                // "application/pdf",
              ],
            },
          })
      );
    }
  }, [loggedIn]);
  const updateLoggedIn = () => {
    // setLoading(true);
    auth((res) => {
      if (res.status === 200) {
        setLoggedIn(true);
        // setUser(res.data.user);
      } else {
        handleLogout();
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    console.log("useffect router");
    updateLoggedIn();
  }, []);

  const handleLogin = () => {
    updateLoggedIn();
  };

  const handleLogout = (url) => {
    localStorage.clear();
    setLoggedIn(false);
    setRedirectUrl(url || "/");
  };

  console.log("router");

  if (loading) {
    return <Loading />;
  } else if (loggedIn) {
    return (
      // <UserContext.Provider user={user}>
      <Routes>
        <Route path="/" element={<Website SEO={SEO.website} type={"home"} />} />
        <Route
          path="/payment"
          element={<Website SEO={SEO.payment} type={"payment"} />}
        />
        <Route
          path="/contactus"
          element={<Website SEO={SEO.contact} type={"contact"} />}
        />
        <Route path="/app/:id" element={<OpenApp SEO={SEO.downloadApp} />} />
        <Route path="/privacy" element={<PP SEO={SEO.pp} />} />
        <Route path="/tnc" element={<TNC SEO={SEO.tnc} />} />
        <Route path="/dmca" element={<DMCA SEO={SEO.dmca} />} />
        <Route
          path="/"
          element={
            <Dashboard
              SEO={SEO.dashboard}
              user={user}
              handleLogout={handleLogout}
            />
          }
        >
          <Route path="analytics" element={<Analytics />} />
          <Route path="videos" element={<Videos uppy={uppy} />} />
          <Route path="branding" element={<Branding />} />
          <Route path="billing" element={<Billing />} />
          <Route
            path="security"
            element={<Security handleLogout={handleLogout} />}
          />
          <Route path="manager" element={<FileManager />} />
          <Route path="botinfo" element={<BotInfo />} />
          <Route path="verification" element={<Verification />} />
        </Route>
        <Route path="*" element={<Navigate to="/analytics" replace={true} />} />
      </Routes>
      // </UserContext.Provider>
    );
  } else {
    return (
      <Routes>
        <Route
          path="/"
          exact
          element={<Website SEO={SEO.website} type={"home"} />}
        />
        <Route
          path="/payment"
          element={<Website SEO={SEO.payment} type={"payment"} />}
        />
        <Route
          path="/contactus"
          element={<Website SEO={SEO.contact} type={"contact"} />}
        />
        <Route path="/tnc" element={<TNC SEO={SEO.tnc} />} />
        <Route path="/privacy" element={<PP SEO={SEO.pp} />} />
        <Route path="/dmca" element={<DMCA SEO={SEO.dmca} />} />
        {isMobile ? (
          <Route
            path="/welcome/:type"
            element={
              <WelcomeMobile handleLogin={handleLogin} SEO={SEO.welcome} />
            }
          />
        ) : (
          <Route
            path="/welcome/:type"
            element={<Welcome handleLogin={handleLogin} SEO={SEO.welcome} />}
          />
        )}

        <Route path="/app/:id" element={<OpenApp SEO={SEO.downloadApp} />} />
        <Route
          path="*"
          element={<Navigate to={redirectUrl} replace={true} />}
        />
      </Routes>
    );
  }
}

export default AppRouter;
