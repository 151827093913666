import { Container, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import LogoImage from "../assests/logo_dash.webp";

function DMCA({ SEO }) {
  return (
    <>
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="description" content={SEO.description} />
      </Helmet>
      <Container style={{ padding: "10px 10px 40px 10px" }}>
        <div style={{ textAlign: "center", padding: "40px 0" }}>
          {/* <Link to="/"> */}
          <img src={LogoImage} style={{ width: 200 }} />
          {/* </Link> */}
          <Typography variant="body2">
            Diskwala: Revolutionize Your Digital Storage and File Sharing.
          </Typography>
        </div>

        <h1 style={{ textAlign: "center" }}>DMCA Policy By DiskWala</h1>
        <p>
          <strong>DiskWala.com</strong> is an Online Service Provider under
          Title II of the Digital Millennium Copyright Act, 17 U. S. C. Section
          512 ("DMCA"). This document outlines the policy that DiskWala.com has
          introduced to implement a notice and takedown procedure as required by
          the DMCA. It provides guidance for copyright owners interested in
          utilizing this procedure, as well as for service users interested in
          restoring access to material mistakenly taken down.
        </p>

        <p>
          DiskWala.com complies with industry standards in the fields of
          copyright law, including Intellectual Property Rights. DiskWala.com
          responds promptly to claims of copyright infringement reported to its
          designated copyright agent. DiskWala.com shall accommodate and not
          interfere with standard technical measures used to identify and
          protect copyrighted works and shall disable access to or remove
          content that it believes in good faith may infringe the copyrights of
          third parties as well as discontinue service to users who repeatedly
          make such content available or otherwise violate DiskWala.com’s Terms
          of Service Agreement and supplemental policies.
        </p>

        <h2 class="section-title">1. Absence of Index</h2>
        <p>
          DiskWala.com is technically unable to index the content being uploaded
          or stored on its servers.
        </p>

        <h2 class="section-title">2. Content Privacy</h2>
        <p>
          DiskWala.com considers any uploaded content to be private and
          confidential unless the user acts contrary.
        </p>

        <h2 class="section-title">
          3. No Authority to Make Judgement of Content
        </h2>
        <p>
          Without distinct Copyright owner’s notification, DiskWala.com has no
          authority to make judgments on whether each user is competent to
          upload content to its servers (e.g., whether such user is the owner of
          the work, or is authorized a proxy of the owner; whether the content
          is uploaded for personal use; whether the copyright has expired; or if
          the content is used under fair use, etc.). Any content is considered
          consistent with Terms of Service unless otherwise notified by the
          copyright owner or any other authorized person in each particular
          case.
        </p>

        <h2 class="section-title">
          4. Writing and Sending Proper Notification
        </h2>
        <p>
          The DMCA provides a legal procedure by which you can request any
          Online Service Provider to disable access to a website where your
          copyrighted work(s) are appearing without your permission.
        </p>
        <p>To submit a valid DMCA Notice, state the following information:</p>
        <ul>
          <li>
            Identify yourself as the owner of a copyrighted work or exclusive
            rights that you believe were infringed, or a person acting on behalf
            of such owner, and provide a physical signature (when in paper form)
            or electronic signature (when in electronic form).
          </li>
          <li>
            Identify the copyrighted work(s) that you believe is/are being
            infringed.
          </li>
          <li>
            Identify the material(s) that allegedly are infringing your
            copyrighted work, by providing Web URL(s) on DiskWala.com site that
            contain these material(s).
          </li>
          <li>
            State your contact information, including your name, street address,
            phone number, and email address. If you are acting on behalf of the
            copyright owner, please also describe your affiliation with the
            copyright holder (e.g., attorney, vendor, agent).
          </li>
          <li>
            State that you have "a good faith belief that use of the
            aforementioned material is not authorized by the copyright owner,
            its agents, or the law."
          </li>
          <li>
            State that the information in the notice is accurate, and under
            penalty of perjury, that you are authorized to act on behalf of the
            owner of an exclusive right that is believed infringed.
          </li>
          <li>
            To exercise your DMCA rights, your Proper DMCA Notice must be sent
            to the Designated Agent of DiskWala.com at the email:{" "}
            <a href="mailto:support@diskwala.com">support@diskwala.com</a>.
          </li>
        </ul>

        <h2 class="section-title">Notice and Takedown Procedure</h2>
        <p>
          DiskWala.com will follow the procedures provided in the DMCA to
          properly enforce the rights of copyright holders. When a Proper DMCA
          notification is received by the Designated Agent, or when DiskWala.com
          becomes otherwise aware that copyright rights are infringed, it will
          remove or disable access to infringing materials as soon as possible.
          You do not need to wait for confirmation from us about this action.
        </p>

        <h2 class="section-title">5. Plain Text</h2>
        <p>
          Any messages, including Takedown notices, are accepted as plain text.
          Any enclosed attachments are not opened or examined due to security
          reasons. The attachments shall be processed only if sent on demand of
          the receiving party.
        </p>
        <p>
          If you believe that any of your copyrights have been violated by a
          user of DiskWala.com, please do not hesitate to address its team in
          the manner set above. Any violating content shall be removed within
          the shortest possible term.
        </p>
      </Container>
    </>
  );
}

export default DMCA;
