import React from "react";
import Typography from "@mui/material/Typography";
import { CircularProgress, Grid } from "@mui/material";
const Loading = () => {
  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      style={{
        // flex: 1,
        height: "100vh",
        backgroundColor: "#c7f9d529",
        position: "fixed",
        width: "100vw",
        top: 0,
        left: 0,
        zIndex: 999999999999,
      }}
    >
      <Grid item>
        <CircularProgress color="primary" />
      </Grid>
      <Grid item>
        <Typography variant="h4" color="primary" style={{ marginLeft: 10 }}>
          Loading DiskWala...
        </Typography>
      </Grid>
    </Grid>
  );
};
export default Loading;
