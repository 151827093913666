import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";
import AppRouter from "./router";
import "./App.css";
import ThemeProvider from "./theme";

ReactGA.initialize("G-9CY1MQHST7");

function App() {
  return (
    <>
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider>
            <SnackbarProvider
              autoHideDuration={3000}
              preventDuplicate
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              dense="true"
              maxSnack={3}
            />
            <Router>
              <AppRouter />
            </Router>
          </ThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
