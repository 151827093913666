import { useMemo } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";

import { palette } from "./palette";
import { shadows } from "./shadows";
import { typography } from "./typography";
import { customShadows } from "./custom-shadows";
import { componentsOverrides } from "./overrides";
import { createPresets } from "./options/presets";
import { createContrast } from "./options/contrast";

export default function ThemeProvider({ children }) {
  const presets = createPresets("default");
  const contrast = createContrast("bold", "light");
  const memoizedValue = useMemo(
    () => ({
      palette: {
        ...palette("light"),
        ...presets.palette,
        ...contrast.palette,
      },
      customShadows: {
        ...customShadows("light"),
        ...presets.customShadows,
      },
      shadows: shadows("light"),
      shape: { borderRadius: 8 },
      typography,
    }),
    [presets.palette, presets.customShadows]
  );

  const theme = createTheme(memoizedValue);

  theme.components = componentsOverrides(theme);

  const themeWithLocale = useMemo(() => createTheme(theme), [theme]);

  return (
    <MuiThemeProvider theme={themeWithLocale}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
